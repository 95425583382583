<template>
  <div class="create-modal-wrapper">
    <q-dialog
      v-model="open"
      class="invite-modal"
    >
      <q-card style="width: 700px; max-width: 75vw">
        <q-card-section>
          <div class="text-h6">
            {{ $t('invite_member') }}
          </div>
        </q-card-section>
        <q-card-section>
          <div class="row text-grey text-body1">
            {{ $t('bulk_upload.mandatory_headers_text') }}
          </div>
          <div class="row q-col-gutter-sm">
            <div class="col-2">
              <q-select
                v-model="newUser.title"
                :error="!!errors.title"
                :error-message="errors.title"
                color="primary"
                :options="selects('titles')"
                :label="$t('title')"
                emit-value
                @change="errors.title = null"
              />
            </div>
            <div class="col">
              <q-input
                v-model="newUser.first_name"
                :error="!!errors.first_name"
                :error-message="errors.first_name"
                :label="$t('name.forename')"
                @change="errors.first_name = null"
              />
            </div>
            <div class="col">
              <q-input
                v-model="newUser.last_name"
                :error="!!errors.last_name"
                :error-message="errors.last_name"
                :label="$t('name.surname')"
                @change="errors.last_name = null"
              />
            </div>
            <div class="col-4">
              <q-input
                v-model="newUser.email"
                :error="!!errors.email"
                :error-message="errors.email"
                :label="$t('email')"
                type="email"
                @change="errors.email = null"
              />
            </div>
          </div>
          <div class="row text-grey text-body1">
            {{ $t('bulk_upload.optional_headers_text') }}
          </div>
          <div class="row q-col-gutter-sm">
            <div class="col">
              <q-input
                v-model="newUser.employee_identifier"
                :label="$t('employee_identifier')"
              />
            </div>
            <div class="col">
              <q-input
                v-model="newUser.department"
                :label="$t('department')"
              />
            </div>
            <div class="col">
              <q-input
                v-model="newUser.division"
                :label="$t('division')"
              />
            </div>
            <div class="col">
              <q-input
                v-model="newUser.cost_centre"
                :label="$t('cost_centre')"
              />
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col text-caption">
              {{ $t('tooltip.invite_many_members') }}:
              <a class="csvOption" @click="$emit('switch-to-upload')">
                {{ $t('upload_csv') }}
              </a>
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-checkbox v-model="inviteMore" :label="$t('invite_another_member')" class="q-mr-md" />
          <q-btn
            color="primary"
            :label="$t('invite_member')"
            :disable="$v.$invalid"
            @click.stop="inviteUser"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { Notify } from 'quasar'

const userTemplate = () => ({
  email: null,
  title: null,
  first_name: null,
  last_name: null,
  employee_identifier: null,
  department: null,
  divison: null,
  cost_centre: null
})

export default {
  name: 'MemberCreate',
  props: ['value', 'organisation'],
  data () {
    return {
      inviteMore: false,
      newUser: userTemplate(),
      errors: userTemplate()
    }
  },
  computed: {
    ...mapGetters({
      selects: 'members/getSelect'
    }),
    open: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    inviteUser () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$store.dispatch('members/inviteUser', {
          orgId: this.organisation,
          user: this.newUser
        }).then((r) => {
          Notify.create({
            message: this.$t('user.invited'),
            color: 'positive',
            icon: 'mdi-check'
          })
          this.$emit('created')
          this.newUser = userTemplate()
          if (!this.inviteMore) {
            this.open = false
            this.$router.push({ name: 'members-view', params: { username: r.data.username, organisation: this.organisation } })
          }
        }).catch(e => {
          Notify.create({
            message: this.$t('error.user_not_invited', { error: e.data.message }),
            color: 'negative',
            icon: 'clear'
          })
          Object.keys(e.data.errors).map(error => {
            this.errors[error] = e.data.errors[error][0]
          })
        })
      }
    },
    clear () {
      this.newUser = userTemplate()
    }
  },
  validations: {
    newUser: {
      title: { required },
      first_name: { required },
      last_name: { required },
      email: { required, email }
    }
  }
}
</script>

<style lang="stylus">
.csvOption
  color $primary
  cursor pointer
  &:hover
    text-decoration underline
.invite-modal > .modal-content
  width: 800px

.invite-modal
  max-height 400px
</style>
