<template>
  <q-card
    v-ripple
    :class="selected === member.username ? 'bg-deep-purple-4 text-white shadow-3' : 'bg-white shadow-1'"
    class="cursor-pointer member"
    @click.prevent="$router.push({ hash: '/user-settings', query: { username: member.username } })"
  >
    <q-card-section class="row">
      <div class="gt-xs col-sm-6 col-md-4 tcell">
        <q-avatar class="avatar small-hide">
          <q-img ratio="1" :src="member.picture" />
        </q-avatar>
        <div>
          {{ member.display_name }}
          <br>
          <small>{{ lastSeen }}</small>
        </div>
      </div>
      <div class="lt-sm col-xs-5 tcell">
        <div>
          {{ member.display_name }}
          <br>
          <small>{{ lastSeen }}</small>
        </div>
      </div>
      <div class="gt-sm col-sm-4 tcell">
        <span>{{ member.email }}</span>
      </div>
      <div class="col-xs-7 col-sm-4 tcell row q-col-gutter-sm">
        <div class="col-sm-5">
          <span><q-chip
            :small="true"
            class="text-white"
            :color="member.is_blocked ? 'negative' : 'positive'"
          >{{ member.is_blocked ? $t('blocked') : $t('active') }}</q-chip></span>
        </div>
        <div class="col-sm-7 actions" @click.stop>
          <q-btn outline label="view" @click.stop="view('profile')" />
          <div class="member-menu">
            <q-btn
              :class="'text-primary'"
              flat
              round
              dense
              icon="more_vert"
              @click.stop="showPopover = !showPopover"
            />
            <q-menu v-model="showPopover">
              <q-list
                link
                style="min-width: 100px"
              >
                <q-item
                  v-for="item in menuItems"
                  :key="item.label"
                  v-close-popup
                  v-ripple
                  clickable
                  @click.native="view(item.tab)"
                >
                  <q-item-section>{{ item.label }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import date from 'utils/date-time'
export default {
  props: {
    member: Object,
    selected: String
  },
  data () {
    return {
      showPopover: false,
      menuItems: [
        {
          label: this.$tc('booking.booking', 2),
          tab: 'bookings'
        },
        {
          label: this.$tc('journey.journey', 2),
          tab: 'journeys'
        },
        {
          label: this.$tc('invoice.invoice', 2),
          tab: 'invoices'
        },
        {
          label: this.$t('notes'),
          tab: 'notes'
        },
        {
          label: this.$t('history'),
          tab: 'history'
        }
      ]
    }
  },
  computed: {
    lastSeen () {
      if (this.member.last_seen_at) {
        return this.$t('last_seen', { date: date.toShortDate(this.member.last_seen_at.date) })
      } else {
        return this.$t('never_logged_in')
      }
    }
  },
  methods: {
    view (tab) {
      this.$router.push(`/organisations/${this.member.organisation.slug}/members/${this.member.username}/${tab}`)
    }
  }
}
</script>

<style lang="stylus" scoped>
.tcell
  display: flex;
  align-items: center;
  justify-content: flex-start;

.avatar
  margin-right 15px

.member-menu
  position: absolute;
  top: 0px;
  right: 12px;

.actions
  height 50px

.member
  position relative

.small-member-list
  .member
    width 96%
  .small-hide
    display none
  .member
    max-height 70px
</style>
